<template>
  <FadeIn 
  class="flex flex-col lg:flex-row lg:items-center max-w-[1180px] mx-auto gap-x-8 gap-y-6 lg:gap-y-20 lg:grid-cols-2 lg:items-start"
  :class="!hasTitle && sectionIndex === 0 ? '' : 'mt-20 lg:mt-32' "
  >
    <div 
    class="lg:px-0 h-auto flex-1"
    :class="brickClasses"
    >
      <div class="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
        
        <dotlottie-player
        v-if="content?.lottie"
        autoplay="true"
        loop="true"
        :src="`https://media.elker.com/${content?.lottie.id}/${content?.lottie.filename_download}`"
        class="rounded-xl overflow-hidden"
        />

        <ImageResponsive
        v-else-if="content?.image"
        classes="rounded-[10px] md:rounded-[15px] ring-1 ring-white/10 bg-[#f3f1ec]"
        :data="content?.image"
        :lazy="true"
        :xs="12"
        :sm="6"
        :md="6"
        :lg="6"
        :xl="6"
        />
      </div>
    </div>
    <div 
    class="flex-1 flex justify-center"
    >
      <div 
      class="lg:max-w-[440px] xl:max-w-[500px]"
      :class="[sectionIndex % 2 ? 'ml-auto' : 'mr-auto']"
      >
        <div class="mb-2 md:mb-4" v-if="content.pretitle">
          <span class="rounded-full mr-2 sm:mr-0 mb-2 sm:mb-0 bg-[#8293fa] px-2 py-0 text-xs inline-block font-semibold leading-6 text-white no-underline">
            {{ content.pretitle }}
          </span>
        </div>
        
        <h2 v-if="content.heading === 'h2'" class="text-xl sm:text-2xl font-bold tracking-tight text-gray-900">
          {{ content.title }}
        </h2>
        <h3 v-else class="text-xl sm:text-2xl font-bold tracking-tight text-gray-900">
          {{ content.title }}
        </h3>
        
        <div 
        v-if="content.content"
        v-interpolation 
        v-html="useSanitize(content.content)" 
        class="text-sm sm:text-base lg:leading-[1.75rem] mt-2 sm:mt-4 prose">
        </div>

        <div 
        v-if="content?.cta?.url"
        class="mt-8"
        >
          <ElementButton
          :url="content.cta.url" 
          :type="content.cta.type"
          :arrow="content.cta.arrow"
          >
            {{ content.cta.title}}
          </ElementButton>
        </div> 

      </div>
    </div>
  </FadeIn>
</template>

<script setup>
const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
  sectionIndex: {
    type: Number
  },
  hasTitle: {
    type: String,
    default: null
  }
})

const brickClasses = computed(() => {
  let brickClass = ''
  if (props.content?.imgGap) {
    if (props.sectionIndex && props.sectionIndex % 2 === 0) {
      brickClass += 'lg:pl-10'
    } else {
      brickClass += 'lg:pr-10'
    }
  }
  if (props.sectionIndex && props.sectionIndex % 2 === 1) {
    brickClass += ''
  } else {
    brickClass += ' lg:order-1'
  }
  return brickClass
})

</script>

<style scoped lang="scss">
.prose {
  ul {
    li {
      margin-bottom: 0;
    }
  }
}
</style>